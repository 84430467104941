<template>
  <div class="articles-table">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              {{ $vuetify.lang.t('$vuetify.order.items') }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="2">
                  <v-select
                    v-model="selectedProject"
                    :items="compProjects"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.different.project')"
                    item-value="id"
                    item-text="name"
                    outlined
                  ></v-select>
                </v-col>
                <v-col md="1" class="hidden-sm-and-down"></v-col>
                <v-col cols="12" md="9">
                  <v-form ref="changePriceForm" lazy-validation v-model="valid">
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-select
                          v-model="priceForm.type"
                          :items="compChangeTypes"
                          :label="$vuetify.lang.t('$vuetify.different.type') + '*'"
                          :rules="notEmpty"
                          required
                          item-value="id"
                          item-text="name"
                          outlined
                          :disabled="compDisabledFormField"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="priceForm.percent"
                          type="number"
                          step="1"
                          min="0"
                          max="100"
                          label="%*"
                          :rules="percentRule"
                          outlined
                          :disabled="compDisabledFormField"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="priceForm.constant"
                          type="number"
                          step=".01"
                          min="0"
                          :label="$vuetify.lang.t('$vuetify.different.constant')"
                          :rules="constantRule"
                          outlined
                          :disabled="compDisabledFormField"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-btn
                          class="ma-2"
                          rounded
                          color="primary"
                          :disabled="compDisabledFormAction"
                          :loading="loadingPriceForm"
                          @click="savePriceForm"
                        >
                          <v-icon left>mdi-content-save</v-icon>
                          {{ $vuetify.lang.t('$vuetify.buttons.save') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="getCategories"
                      >
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.buttons.refresh') }}</span>
                  </v-tooltip>
                  <v-treeview
                    v-model="selectedCategories"
                    selectable
                    :items="categories"
                  ></v-treeview>
                  <p v-show="!categories.length">{{ $vuetify.lang.t('$vuetify.different.selectProject') }}</p>
                </v-col>
                <v-divider vertical class="hidden-sm-and-down"></v-divider>
                <v-col cols="12" md="9">

                  <v-row>
                    <v-col cols="12" md="2">
                      <v-select
                        v-model="selectedCustomerGroup"
                        :items="customerGroups"
                        :menu-props="{ maxHeight: '400' }"
                        :label="$vuetify.lang.t('$vuetify.user.customerGroup')"
                        item-value="key"
                        item-text="name"
                        :disabled="!selectedProject"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-switch
                        v-model="filters.in_stock"
                        :label="$vuetify.lang.t('$vuetify.order.inStock')"
                      ></v-switch>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="12" md="4" v-show="compDisplayPercentForm">
                      <v-form ref="changeFHPercent" lazy-validation v-model="validPercentForm">
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="percentForm.percent"
                              type="number"
                              step="1"
                              min="0"
                              max="100"
                              label="HR %*"
                              :rules="percentRule"
                              outlined
                              :disabled="compDisabledFormField"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-btn
                              class="ma-2"
                              rounded
                              color="primary"
                              :disabled="compDisabledPercentFormAction"
                              :loading="loadingPercentForm"
                              @click="savePercentForm"
                            >
                              <v-icon left>mdi-content-save</v-icon>
                              {{ $vuetify.lang.t('$vuetify.buttons.save') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                  <v-data-table
                    :headers="compHeader"
                    :items="items"
                    fixed-header
                    class="elevation-1"
                    item-key="id"
                    v-model="selectedArticles"
                    show-select
                  >
                    <template v-slot:item.warehouseprice="{ item }">
                      {{ item.warehouseprice }}
                      <v-tooltip bottom v-if="item.warehouseprice_explain.length">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">mdi-comment-question-outline</v-icon>
                        </template>
                        <p class="ma-0" v-for="(entry, i) in item.warehouseprice_explain" :key="i">{{ entry }}</p>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.aftersaleprice="{ item }">
                      {{ item.aftersaleprice }}
                      <v-tooltip bottom v-if="item.aftersaleprice_explain.length">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">mdi-comment-question-outline</v-icon>
                        </template>
                        <p class="ma-0" v-for="(entry, i) in item.aftersaleprice_explain" :key="'after_'+i">
                          {{ entry }}</p>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.purchaseprice="{ item }">
                      {{ item.purchaseprice }} {{ item.purchasecurrency }}
                    </template>

                    <template v-slot:item.profitpercent="{ item }">
                      <span :class="getPercentColor(item.profitpercent)">
                        {{ item.profitpercent }}
                      </span>
                    </template>

                    <template v-slot:item.action="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-on="on"
                            v-bind="attrs"
                            small
                            @click="showItemProfilesModal(item)"
                          >
                            <v-icon :color="item.applied_item_profiles ? 'green' : ''">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $vuetify.lang.t('$vuetify.buttons.view') }}</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialogProfiles"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeItemProfilesModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ selectedArticle.ordernumber }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card flat>
          <v-card-title>{{ $vuetify.lang.t('$vuetify.user.profiles') }}
            <v-spacer></v-spacer>
            <v-card class="d-flex justify-end pa-2" flat tile>
              <v-row>

                <v-select
                  v-model="selectedTemplates"
                  :items="templates"
                  :menu-props="{ maxHeight: '400'}"
                  :label="$vuetify.lang.t('$vuetify.different.profitTemplates')"
                  item-value="id"
                  item-text="name"
                  outlined
                  multiple
                  class="mr-2"
                  style="max-width: 300px;"
                ></v-select>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-1"
                      fab
                      small
                      v-on="on"
                      v-bind="attrs"
                      @click="applyTemplates"
                    >
                      <v-icon>mdi-content-duplicate</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.buttons.addTemplates') }}</span>
                </v-tooltip>
              </v-row>
            </v-card>

          </v-card-title>
          <v-toolbar flat color="white">
            <v-text-field
              text
              solo
              flat
              clearable
              prepend-icon="search"
              :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
              v-model="searchProfile"
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="getProfiles"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.buttons.refresh') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  color="success"
                  @click="createProfile"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.buttons.create') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  color="red"
                  small
                  :disabled="!selectedProfiles.length"
                  @click="dialogDeleteProfile = true"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{
                  $vuetify.lang.t('$vuetify.buttons.delete')
                }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="compProfilesHeader"
              :items="profiles"
              fixed-header
              class="elevation-1"
              item-key="id"
              v-model="selectedProfiles"
              :search="searchProfile"
              show-select
              :items-per-page="-1"
              hide-default-footer
              :loading="loadingProfiles"
            >
              <template v-slot:item.is_percent="{ item }">
                <v-simple-checkbox
                  v-model="item.is_percent"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-on="on"
                      v-bind="attrs"
                      small
                      @click="editProfile(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.buttons.edit') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-on="on"
                      v-bind="attrs"
                      small
                      @click="deleteProfile(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                      $vuetify.lang.t('$vuetify.buttons.delete')
                    }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogProfile" max-width="500">
      <v-card>
        <v-card-title>
          <span class="headline"> {{ compDialogCardTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="profileForm" lazy-validation v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="profileItem.type"
                    :items="profileTypes"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.different.type') + '*'"
                    :rules="notEmpty"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="profileItem.name"
                    :label="$vuetify.lang.t('$vuetify.different.title') + '*'"
                    :hint="$vuetify.lang.t('$vuetify.different.title')"
                    :rules="max255"
                    required
                    counter
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="3">
                      <v-checkbox
                        v-model="profileItem.is_percent"
                        label="%"
                        color="primary"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="9">
                      <v-text-field
                        v-model="profileItem.value"
                        type="number"
                        step=".01"
                        min=".01"
                        :label="$vuetify.lang.t('$vuetify.different.value') + '*'"
                        :hint="$vuetify.lang.t('$vuetify.different.value')"
                        :rules="numberRule"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ $vuetify.lang.t('$vuetify.different.indicate') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogProfile = false"
            :disabled="loadingProfile"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="storeProfile"
            :disabled="!valid"
            :loading="loadingProfile"
          >
            {{ compAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteProfile" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{
            $vuetify.lang.t('$vuetify.different.deleteItemConfirm')
          }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDeleteProfile = false"
            :disabled="loading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteProfileConfirm"
            :loading="loading"
          >{{ $vuetify.lang.t('$vuetify.buttons.delete') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
    </v-snackbar>
    <AppLoader :dialog.sync="loading"></AppLoader>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  data() {
    return {
      valid: false,
      validPercentForm: false,
      loading: false,
      loadingPriceForm: false,
      loadingPercentForm: false,
      loadingProfiles: false,

      search: null,
      awaitingSearch: false,
      categories: [],
      selectedCategories: [],
      selectedArticles: [],
      items: [],
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      selectedProject: null,
      customerGroups: [],
      selectedCustomerGroup: null,
      selectedArticle: {
        id: null,
        ordernumber: null,
        categoryID: null,
      },
      defaultArticle: {
        id: null,
        ordernumber: null,
        categoryID: null,
      },
      priceForm: {
        type: 'increase',
        percent: 0,
        constant: 0
      },
      percentForm: {
        percent: 0,
        type: 'percentFromDefaultPriceGroup'
      },


      templates: [],
      selectedTemplates: [],
      loadingProfile: false,
      selectedProfiles: [],
      dialogProfiles: false,
      dialogProfile: false,
      dialogDeleteProfile: false,
      profiles: [],
      searchProfile: null,
      profileTypes: ['purchase', 'net'],
      profileItem: {
        id: null,
        project_id: null,
        category_id: null,
        article_detail_id: null,
        type: 'purchase',
        name: null,
        is_percent: false,
        value: null,
        sort_order: null,
        created_at: null,
      },
      defaultProfileItem: {
        id: null,
        project_id: null,
        category_id: null,
        article_detail_id: null,
        type: 'purchase',
        name: null,
        is_percent: false,
        value: null,
        sort_order: null,
        created_at: null,
      },

      filters: {
        in_stock: localStorage.getItem('filter_items_in_stock')
          ? JSON.parse(localStorage.getItem('filter_items_in_stock'))
          : false,
      },

      notEmpty: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required')
      ],
      percentRule: [
        (v) => (!v || parseInt(v) >= 0) || this.$vuetify.lang.t('$vuetify.validation.minNumber') + '0',
        (v) => (!v || parseInt(v) <= 100) || this.$vuetify.lang.t('$vuetify.validation.maxNumber') + '100'
      ],
      constantRule: [
        (v) => (!v || parseFloat(v) >= 0) || this.$vuetify.lang.t('$vuetify.validation.minNumber') + '0',
      ],
      numberRule: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        (v) => parseFloat(v) >= 0.01 || this.$vuetify.lang.t('$vuetify.validation.incorrect'),
      ],
      max255: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        (v) =>
          (v && v.length <= 255) ||
          this.$vuetify.lang.t('$vuetify.validation.max255')
      ],
    }
  },
  watch: {
    selectedProject(val) {
      if (val) {
        this.selectedCategories = []
        this.getCategories()
        this.getProjectCustomerGroups()
      } else {
        this.categories = []
        this.customerGroups = []
        this.selectedCustomerGroup = null
      }
    },
    selectedCategories() {
      this.getArticles()
    },
    selectedCustomerGroup(val) {
      if(val && this.selectedCategories.length)
        this.getArticles()
    },
    'filters.in_stock': function (val) {
      localStorage.setItem('filter_items_in_stock', val)
      this.getArticles()
    },
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.order.items')
    }
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          responseType: 'application/json'
        }
      }
    },
    compHeader() {
      if (this.selectedCustomerGroup && this.selectedProject) {
        if (this.selectedCustomerGroup === 'FH') {
          return [
            {
              text: this.$vuetify.lang.t('$vuetify.order.itemNumber'),
              value: 'ordernumber'
            },
            {
              text: this.$vuetify.lang.t('$vuetify.order.purchasePrice'),
              value: 'purchaseprice'
            },
            {
              text: this.$vuetify.lang.t('$vuetify.order.warehousePrice'),
              value: 'warehouseprice'
            },
            {
              text: this.$vuetify.lang.t('$vuetify.order.net') + ' Shopware',
              value: 'nettoprice'
            },
            {
              text: this.$vuetify.lang.t('$vuetify.order.net') + ' Fanatik-Händler',
              value: 'nettoprice_FH'
            },
            {
              text: 'HR %',
              value: 'percent_FH'
            },
            {
              text: '%',
              value: 'profit_percent_FH'
            },
            {
              text: this.$vuetify.lang.t('$vuetify.buttons.action'),
              value: 'action',
              sortable: false
            }
          ]
        }
      }
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.order.itemNumber'),
          value: 'ordernumber'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.purchasePrice'),
          value: 'purchaseprice'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.warehousePrice'),
          value: 'warehouseprice'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.net') + ' Shopware',
          value: 'nettoprice'
        },
        {
          text: 'Brutto',
          value: 'bruttoprice'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.afterSalePrice'),
          value: 'aftersaleprice'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.profit') + ' EUR',
          value: 'profitprice'
        },
        {
          text: '%',
          value: 'profitpercent'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    },
    compProjects() {
      return this.$store.getters.getProjects
    },
    compChangeTypes() {
      return [
        {
          id: 'increase',
          name: this.$vuetify.lang.t('$vuetify.different.increase')
        },
        {
          id: 'decrease',
          name: this.$vuetify.lang.t('$vuetify.different.decrease')
        },
      ]
    },
    compDisabledFormField() {
      return !this.selectedCategories.length
    },
    compDisabledFormAction() {
      return !this.selectedCategories.length || !this.valid || !(parseInt(this.priceForm.percent) > 0 || parseFloat(this.priceForm.constant) > 0)
    },
    compDisabledPercentFormAction() {
      return !this.selectedCategories.length || !this.validPercentForm || !(parseInt(this.percentForm.percent) > 0)
    },
    compDisplayPercentForm() {
      return this.selectedCustomerGroup === 'FH'
    },
    compProfilesHeader() {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.different.type'),
          value: 'type',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.title'),
          value: 'name',
        },
        {
          text: '%',
          value: 'is_percent',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.value'),
          value: 'value',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    },
    compDialogCardTitle() {
      return this.profileItem.id
        ? this.$vuetify.lang.t('$vuetify.different.editItem')
        : this.$vuetify.lang.t('$vuetify.different.newItem')
    },
    compAction() {
      return this.profileItem.id
        ? this.$vuetify.lang.t('$vuetify.buttons.save')
        : this.$vuetify.lang.t('$vuetify.buttons.create')
    }
  },
  mounted() {
    this.getTemplates()
  },
  methods: {
    getPercentColor(value) {
      if (parseFloat(value) < 18)
        return 'red--text font-weight-bold'

      return ''
    },

    getCategories() {
      if (this.selectedProject) {
        this.loading = true
        let data = {
          project_id: this.selectedProject,
          type: 'tree'
        }

        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/category',
            data,
            this.compAuthHeader
          )
          .then((response) => {
            if (response.data.success) {
              this.categories = response.data.items
            } else {
              this.categories = []
              this.showSnackbar(
                'red',
                this.$vuetify.lang.t('$vuetify.errors.error')
              )
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.categories = []
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          })
      } else this.categories = []
    },
    getArticles() {
      if (this.selectedCategories.length) {
        this.loading = true
        let data = {
          project_id: this.selectedProject,
          categories: this.selectedCategories,
          in_stock: this.filters.in_stock,
          customer_group: this.selectedCustomerGroup
        }

        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/articleprice',
            data,
            this.compAuthHeader
          )
          .then((response) => {
            if (response.data.success) {
              this.items = response.data.items
            } else {
              this.items = []
              this.showSnackbar(
                'red',
                this.$vuetify.lang.t('$vuetify.errors.error')
              )
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.items = []
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          })
      } else this.items = []
    },
    savePriceForm() {
      if (this.$refs.changePriceForm.validate() && this.selectedCategories.length
        && (parseInt(this.priceForm.percent) > 0 || parseFloat(this.priceForm.constant) > 0)) {
        this.loading = this.loadingPriceForm = true
        let data = {
          project_id: this.selectedProject,
          categories: this.selectedCategories,
          articles: this.selectedArticles.map(i => i.id),
          group: typeof this.selectedCustomerGroup === 'object' ? this.selectedCustomerGroup.key : this.selectedCustomerGroup,
          ...this.priceForm
        }

        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/articleprice/store',
            data,
            this.compAuthHeader
          )
          .then((response) => {
            if (response.data.success) {
              this.loadingPriceForm = false
              this.getArticles()
            } else {
              this.loading = this.loadingPriceForm = false
              this.showSnackbar(
                'red',
                this.$vuetify.lang.t('$vuetify.errors.error')
              )
            }
          })
          .catch(() => {
            this.loading = this.loadingPriceForm = false
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          })
      }
    },
    savePercentForm() {
      if (this.$refs.changeFHPercent.validate() && this.selectedCategories.length
        && (parseInt(this.percentForm.percent) > 0)) {
        this.loading = this.loadingPercentForm = true
        let data = {
          project_id: this.selectedProject,
          categories: this.selectedCategories,
          articles: this.selectedArticles.map(i => i.id),
          group: this.selectedCustomerGroup,
          ...this.percentForm
        }

        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/articleprice/store',
            data,
            this.compAuthHeader
          )
          .then((response) => {
            if (response.data.success) {
              this.loadingPercentForm = false
              this.getArticles()
            } else {
              this.loading = this.loadingPercentForm = false
              this.showSnackbar(
                'red',
                this.$vuetify.lang.t('$vuetify.errors.error')
              )
            }
          })
          .catch(() => {
            this.loading = this.loadingPercentForm = false
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          })
      }
    },
    showSnackbar(color, text) {
      this.snackbar.show = true
      this.snackbar.color = color
      this.snackbar.text = text
    },
    getProjectCustomerGroups() {
      if (this.selectedProject != null) {

        this.loading = true
        let data = {
          project_id: this.selectedProject,
        }

        this.$axios
          .post(this.$store.getters.getApiUrl + 'api/customergroup', data, this.compAuthHeader)
          .then((response) => {
            this.customerGroups = response.data.data
            this.selectedCustomerGroup = response.data.data[0].key
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.customerGroups = []
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error')
            )
          })

      }
    },
    showItemProfilesModal(item) {
      this.selectedArticle = Object.assign({}, item)
      this.getProfiles()
      this.dialogProfiles = true
    },
    closeItemProfilesModal() {
      this.dialogProfiles = false
      this.selectedArticle = Object.assign({}, this.defaultArticle)
      this.profiles = []
      this.getArticles()
    },
    getProfiles() {
      this.loadingProfiles = true
      let data = {
        project_id: this.selectedProject,
        article_detail_id: this.selectedArticle.id,
      }

      this.$axios
        .post(this.$store.getters.getApiUrl + 'api/profile', data, this.compAuthHeader)
        .then((response) => {
          if (response.status === 200) {
            this.profiles = response.data.data
          } else {
            this.profiles = []
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          }
          this.loadingProfiles = false
        })
        .catch(() => {
          this.loadingProfiles = false
          this.profiles = []
          this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error')
          )
        })
    },
    storeProfile() {
      if (this.$refs.profileForm.validate()) {
        this.loading = this.loadingProfile = true

        if (!this.profileItem.project_id) this.profileItem.project_id = this.selectedProject
        if (!this.profileItem.article_detail_id) this.profileItem.article_detail_id = parseInt(this.selectedArticle.id)

        let url = 'api/profile/store'
        if (this.profileItem.id) url = 'api/profile/update'

        this.$axios
          .post(this.$store.getters.getApiUrl + url, this.profileItem, this.compAuthHeader)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.loading = this.loadingProfile = false
              this.showSnackbar('green', this.$vuetify.lang.t('$vuetify.messages.saved'))
              this.dialogProfile = false
              this.$refs.profileForm.resetValidation()
              this.getProfiles()
            } else {
              this.loading = this.loadingProfile = false
              this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
            }
          })
          .catch(() => {
            this.loading = this.loadingProfile = false
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          })
      }
    },
    createProfile() {
      this.profileItem.sort_order = this.profiles.length + 1
      this.dialogProfile = true
      if (this.$refs.profileForm) this.$refs.profileForm.resetValidation()
    },
    editProfile(item) {
      this.profileItem = Object.assign({}, item)
      this.dialogProfile = true
    },
    deleteProfile(item) {
      this.profileItem = Object.assign({}, item)
      this.dialogDeleteProfile = true
    },
    deleteProfileConfirm() {
      let arrIds = []
      if (this.profileItem.id) arrIds.push(this.profileItem.id)
      else if (this.selectedProfiles.length) {
        this.selectedProfiles.forEach(function (entry) {
          arrIds.push(entry.id)
        })
      }

      if (arrIds.length) {
        this.loading = true
        this.$axios
          .post(this.$store.getters.getApiUrl + 'api/profile/destroy', {ids: arrIds}, this.compAuthHeader)
          .then((response) => {
            if (response.status === 200) {
              this.dialogDeleteProfile = false
              this.profileItem = Object.assign({}, this.defaultProfileItem)
              this.selectedProfiles = []
              this.showSnackbar('green', this.$vuetify.lang.t('$vuetify.messages.deleted'))
              this.getProfiles()
            } else {
              this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          })
      }
    },
    applyTemplates() {
      let data = {
        project_id: this.selectedProject,
        article_detail_id: this.selectedArticle.id,
        templates_ids: this.selectedTemplates
      }
      this.loading = true

      this.$axios
        .post(this.$store.getters.getApiUrl + 'api/profile/templates', data, this.compAuthHeader)
        .then((response) => {
          if (response.status === 200) {
            this.getProfiles()
            this.selectedTemplates = []
          } else {
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
        })
    },
    getTemplates() {
      this.$axios
        .post(this.$store.getters.getApiUrl + 'api/profile-template', {}, this.compAuthHeader)
        .then((response) => {
          if (response.status === 200) {
            this.templates = response.data.data
          } else {
            this.templates = []
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          }
        })
        .catch(() => {
          this.templates = []
          this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
        })
    },
  }
}
</script>
